.jumbotron {
	background-color: #bbcaff;
	font-size: 16px;
	text-align: center;
  border-radius: 6px;
	margin-bottom: 30px;
	font-weight: 200;
	line-height: 2.1428571435;
}
.jumbotron h1 {
	font-size: 25px;
}
@media screen and (min-width: 768px){
	.jumbotron {
		padding: 20px 10px 20px 10px;
	}	
}
.selectedjoin {
  color: white;
}
.selectedjoin:before {
  content: "\2713 ";
}

.generate-container .row {
  margin-bottom: 10px;
}