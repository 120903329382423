body {
  margin: 0;
  background: #95B3D7; 
}

.logo-container img {
  max-width: 100%;
}

.logo-container {
  font-size: 0.8rem;
}

.content {
	background-color: white;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding-top: 10px;
  margin-bottom: 20px;
}

.menu .nav-link {
  color: #ffffff;
}
.menu .navbar .active {
	color: black;
	background-color: #E7E7E7;
}

.footer img {
  max-height: 300px;
  max-width: 100%;
}